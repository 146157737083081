import littledata from '@littledata/headless-shopify-sdk';
import { getCookie } from 'cookies-next';
import clone from 'just-clone';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { ShopReducer, ShopReducerTypes } from '@/context/ShopContextReducer';
import type {
  AttributeInput,
  CartBuyerIdentityUpdateMutationOptions,
  CartDeliveryAddressesAddMutationOptions,
} from '@/generated/shopify';
import { useTypedApolloClient } from '@/lib/apollo/apollo-client';
import { sendSentryError } from '@/lib/sentry/sentry';
import type {
  ShopContextProviderProps,
  ShopContextType,
  ShopStateType,
  UpsertManyToCartParams,
  UpsertToCartParams,
} from '@/models/shop-context';
import type { ShopifyCart } from '@/models/shop-context-cart';
import { ShopifyService } from '@/services/shopify';
import { buyNowAnalytics, viewItem } from '@/utils/analytics';

const REGULAR_PRICE = 99;

const initialState: ShopStateType = {
  cart: {} as ShopifyCart,
  discountMap: {},
  cartAttributes: [],
  cartError: null,
};

export const ShopContext = createContext<ShopContextType>({
  state: initialState,
  dispatch: () => null,
  upsertToCart: async () => {},
  upsertManyToCart: async () => {},
  applyDiscounts: async () => ({}) as ShopifyCart,
  appendAttributes: async () => {},
  clearCart: async () => {},
  updateCartBuyerIdentity: async () => {},
  addCartDeliveryAddresses: async () => {},
  goToCheckout: async () => {},
  cartIsAvailable: false,
  cartIsReadyForCheckout: true,
  segmentAnonymousId: null,
  cartTotalPrice: () => '',
  cartTotalPriceNumber: () => 0,
  cartSaveAmount: () => '',
  regularPrice: REGULAR_PRICE,
});

export const useShopContext = () => {
  const context = React.useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShopContext must be used within a ShopContextProvider');
  }
  return context;
};

export const ShopContextProvider: React.FC<ShopContextProviderProps> = ({
  children,
  segmentAnonymousId,
  availableDiscountCodes,
}) => {
  const client = useTypedApolloClient();
  const regularPrice = REGULAR_PRICE;
  const [state, dispatch] = useReducer(ShopReducer, initialState);
  const [cartIsAvailable, setCartIsAvailable] = useState(false);
  const [cartIsReadyForCheckout, setCartIsReadyForCheckout] = useState(false);

  const [cartId, setCartId] = useState<string>('');

  const cartInitializeAttempted = useRef(false);

  const shopifyService = useMemo(() => new ShopifyService(client), [client]);

  const clearCart = useCallback(async () => {
    const lineIds = await shopifyService.fetchCartLines(cartId);
    if (!lineIds?.length) return;

    const cart = await shopifyService.removeCartLines(cartId, lineIds);
    dispatch({ type: ShopReducerTypes.DeleteCartItem, payload: cart });
  }, [shopifyService, cartId]);

  const _cartHasMerchandiseId = useCallback(
    async (merchandiseId: string): Promise<boolean> => {
      // INFO: _cartHasMerchandiseId assumes only 1 item in the cart
      // If there is more than 1 item. clearCart
      if (state.cart?.lines?.edges?.length > 1) {
        await clearCart();
        return false;
      }

      const foundEdge = state.cart?.lines?.edges.find((edge) => {
        return edge.node.merchandise.id === merchandiseId;
      });

      if (!foundEdge && state.cart?.lines?.edges?.length === 1) {
        // INFO: We found an item, but it was not the one we were looking for. clearCart
        await clearCart();
        return false;
      }

      return !!foundEdge;
    },
    [state.cart?.lines?.edges, clearCart]
  );

  const _shouldUpdateDiscountCodes = useCallback(
    (discountCodes: string[]): [boolean, string[]] => {
      let shouldUpdate = false;
      const discountCodesForUpdate: string[] = clone(state.cart.discountCodes).map(
        (discountCode) => discountCode.code
      );

      discountCodes.forEach((discountCode) => {
        const foundDiscountCode = discountCodesForUpdate.find(
          (existingCode) => existingCode === discountCode
        );
        if (!foundDiscountCode) {
          discountCodesForUpdate.push(discountCode);
          shouldUpdate = true;
        }
      });
      return [shouldUpdate, discountCodesForUpdate];
    },
    [state.cart.discountCodes]
  );

  const applyDiscounts = useCallback(
    async (discountCodes: string[]) => {
      try {
        const [shouldUpdate, discountCodesForUpdate] = _shouldUpdateDiscountCodes(discountCodes);

        if (!shouldUpdate) {
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.addDiscountToCart({
          cartId,
          discountCodes: discountCodesForUpdate,
        });
        dispatch({ type: ShopReducerTypes.UpdateCart, payload: cart! });
        setCartIsReadyForCheckout(true);
        return cart;
      } catch (error) {
        sendSentryError('Failed to apply discounts to cart', error);
        dispatch({
          type: ShopReducerTypes.SetCartError,
          payload: 'Failed to apply discounts to cart',
        });
      }
    },
    [shopifyService, cartId, _shouldUpdateDiscountCodes]
  );

  const _shouldUpdateAttributes = useCallback(
    (attributes: AttributeInput[]): [boolean, AttributeInput[]] => {
      let shouldUpdate = false;
      const attributesForUpdate = clone(state.cart.attributes).map((attribute) => ({
        // INFO: Removing __typename
        key: attribute.key,
        value: attribute.value as string,
      }));

      attributes.forEach((attribute) => {
        const foundAttribute = attributesForUpdate.find(
          (existingAttribute) => existingAttribute.key === attribute.key
        );
        if (foundAttribute) {
          if (attribute.value !== foundAttribute.value) {
            foundAttribute.value = attribute.value;
            shouldUpdate = true;
          }
        } else {
          attributesForUpdate.push(attribute);
          shouldUpdate = true;
        }
      });
      return [shouldUpdate, attributesForUpdate];
    },
    [state.cart.attributes]
  );

  const appendAttributes = useCallback(
    async (attributes: AttributeInput[]) => {
      try {
        const [shouldUpdate, attributesForUpdate] = _shouldUpdateAttributes(attributes);
        if (!shouldUpdate) {
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.updateCartAttributes({
          cartId,
          attributes: attributesForUpdate,
        });
        dispatch({ type: ShopReducerTypes.UpdateCart, payload: cart! });
        setCartIsReadyForCheckout(true);
      } catch (error) {
        sendSentryError('Failed to append attributes', error);
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to append attributes' });
      }
    },
    [cartId, shopifyService, _shouldUpdateAttributes]
  );

  // INFO: upsertToCart assumes 1 item ever only in cart.
  // If you need multiple items, use upsertManyToCart.
  const upsertToCart = useCallback(
    async ({ merchandiseId, discountCodes, ...params }: UpsertToCartParams) => {
      const quantity = params.quantity || 1;
      try {
        if (await _cartHasMerchandiseId(merchandiseId)) {
          // console.debug(`# DEBUG: ALREADY HAS MERCHANDISE_ID: ${merchandiseId}`);
          if (discountCodes && discountCodes.length > 0) {
            const applyDiscountCart = await applyDiscounts(discountCodes);
            if (applyDiscountCart) {
              viewItem(applyDiscountCart);
            }
          }
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const addToCartCart = await shopifyService.addToCart({
          lines: [
            {
              merchandiseId,
              quantity,
            },
          ],
          cartId,
        });
        dispatch({ type: ShopReducerTypes.AddToCart, payload: addToCartCart });
        if (discountCodes && discountCodes.length > 0) {
          const applyDiscountCart = await applyDiscounts(discountCodes);
          if (applyDiscountCart) {
            viewItem(applyDiscountCart);
          }
        }
        setCartIsReadyForCheckout(true);
      } catch (error) {
        sendSentryError('Failed to add to cart', error);
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to add to cart' });
      }
    },
    [_cartHasMerchandiseId, shopifyService, applyDiscounts, cartId]
  );

  const upsertManyToCart = useCallback(
    async ({ lines, discountCodes, attributes }: UpsertManyToCartParams) => {
      try {
        if (state.cart?.lines?.edges?.length > 0) {
          // INFO: When upserting many, if there is already items, remove them first.
          await clearCart();
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.addToCart({
          lines,
          cartId,
        });
        if (attributes) {
          await appendAttributes(attributes);
        }
        dispatch({ type: ShopReducerTypes.AddToCart, payload: cart });
        if (discountCodes && discountCodes.length > 0) {
          await applyDiscounts(discountCodes);
        }
        setCartIsReadyForCheckout(true);
      } catch (error) {
        sendSentryError('Failed to add many to cart', error);
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to add many to cart' });
      }
    },
    [
      cartId,
      state.cart?.lines?.edges?.length,
      shopifyService,
      clearCart,
      appendAttributes,
      applyDiscounts,
    ]
  );

  const addCartDeliveryAddresses = useCallback(
    async (
      addresses: NonNullable<CartDeliveryAddressesAddMutationOptions['variables']>['addresses']
    ) => {
      const result = await shopifyService.addCartDeliveryAddresses({ cartId, addresses });
      dispatch({ type: ShopReducerTypes.UpdateCart, payload: result });
    },
    [cartId, shopifyService]
  );

  const updateCartBuyerIdentity = useCallback(
    async (
      buyerIdentity: NonNullable<
        CartBuyerIdentityUpdateMutationOptions['variables']
      >['buyerIdentity'],
      addresses?: NonNullable<CartDeliveryAddressesAddMutationOptions['variables']>['addresses']
    ) => {
      const result = await shopifyService.updateCartBuyerIdentity({ cartId, buyerIdentity });

      if (addresses) {
        await addCartDeliveryAddresses(addresses);
      }

      dispatch({ type: ShopReducerTypes.UpdateCart, payload: result });
    },
    [cartId, shopifyService, addCartDeliveryAddresses]
  );

  const goToCheckout = useCallback(async () => {
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventValue: {},
      eventName: `GO_TO_CHECKOUT`,
    });
    // INFO: Do this first before redirect
    await buyNowAnalytics(state.cart);
    dispatch({ type: ShopReducerTypes.Checkout });
  }, [state.cart]);

  const _prepareCartAttributes = useCallback(
    (segmentAnonymousId: string): AttributeInput[] | undefined => {
      try {
        const attributes: AttributeInput[] = [
          {
            key: '_segment-clientID',
            value: segmentAnonymousId,
          },
        ];
        const vwoUuid = getCookie('_vwo_uuid');
        if (typeof vwoUuid === 'string' && vwoUuid) {
          attributes.push({ key: '_vwo_uuid', value: vwoUuid });
        }
        console.log(_prepareCartAttributes, JSON.stringify(attributes));
        return attributes;
      } catch (error) {
        sendSentryError('Failed to _prepareCartAttributes:', error);
        return undefined;
      }
    },
    []
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!segmentAnonymousId || cartInitializeAttempted.current) return;
    console.debug('# DEBUG: Initializing Cart');
    cartInitializeAttempted.current = true;

    const initializeCart = async () => {
      const shopCartId = getCookie('shop_cart_id');
      if (typeof shopCartId === 'string') {
        try {
          const cartDetails = await shopifyService.cartDetails(shopCartId);
          dispatch({ type: ShopReducerTypes.CreateCart, payload: cartDetails });
          setCartId(cartDetails.id); // Set cartId here
          setCartIsAvailable(true);
        } catch (error) {
          // If loading fails, create a new cart
          await createNewCart();
        }
      } else {
        await createNewCart();
      }
    };

    const createNewCart = async () => {
      if (!segmentAnonymousId) return;
      try {
        const cart = await shopifyService.createCart({
          input: {
            attributes: _prepareCartAttributes(segmentAnonymousId),
          },
        });
        const cartToken = shopifyService.extractCartToken(cart.id);
        if (!cartToken) throw new Error('Invalid cart token');
        dispatch({ type: ShopReducerTypes.CreateCart, payload: cart });
        setCartId(cart.id);
        setCartIsAvailable(true);
        await littledata.sendCartToLittledata(cartToken);
        await shopifyService.sendCampaignData(cart.id);
      } catch (error) {
        sendSentryError('Failed to create cart', error);
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to create cart' });
      }
    };

    initializeCart();
  }, [_prepareCartAttributes, segmentAnonymousId, shopifyService]);

  const cartTotalPrice = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return '';
    }
    return `$${parseFloat(state.cart.cost.subtotalAmount.amount).toFixed(2)}`;
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const cartTotalPriceNumber = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return 0;
    }
    return parseFloat(state.cart.cost.subtotalAmount.amount);
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const cartSaveAmount = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return '';
    }
    return `$${(REGULAR_PRICE - parseFloat(state.cart.cost.subtotalAmount.amount)).toFixed(2)}`;
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      upsertToCart,
      upsertManyToCart,
      applyDiscounts,
      appendAttributes,
      clearCart,
      updateCartBuyerIdentity,
      addCartDeliveryAddresses,
      goToCheckout,
      cartIsAvailable,
      cartIsReadyForCheckout,
      segmentAnonymousId,
      cartTotalPrice,
      cartTotalPriceNumber,
      cartSaveAmount,
      regularPrice,
    }),
    [
      state,
      dispatch,
      upsertToCart,
      upsertManyToCart,
      applyDiscounts,
      appendAttributes,
      clearCart,
      updateCartBuyerIdentity,
      addCartDeliveryAddresses,
      goToCheckout,
      cartIsAvailable,
      cartIsReadyForCheckout,
      segmentAnonymousId,
      cartTotalPrice,
      cartTotalPriceNumber,
      cartSaveAmount,
      regularPrice,
    ]
  );

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};
